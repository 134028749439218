import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Images/WebShop/WebShop-Logo.png";

export default function Navigation({ wishlist }) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = () => {
    console.log("Searching for:", searchQuery);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <header>
        <nav>
          <Link className="header-link" to="/" onClick={() => handleClick("/")}>
            <img className="logo" src={Logo} alt="WebShop logo" />
          </Link>

          <div className="header-btn-box">
            <button className="header-btn">
              <span className="material-symbols-outlined">account_circle</span>
            </button>
          </div>
        </nav>
        <div className="header-search-bar">
          <span
            className="material-symbols-outlined"
            onClick={handleSearch}
            style={{ cursor: "pointer" }}
          >
            search
          </span>
          <input
            type="text"
            placeholder="Search phones, headphones, laptops, cameras..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </header>
    </>
  );
}
