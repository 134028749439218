import React from "react";

export default function Wishlist({ wishlist, toggleWishlist, showWishlist }) {
  return (
    <>
      <button
        className={`openWishlist-btn open-btn ${showWishlist ? "active" : ""}`}
        onClick={toggleWishlist}
      >
        <span className="material-symbols-outlined">favorite</span>
        {wishlist.length > 0 && (
          <span className="count">{wishlist.length}</span>
        )}
      </button>

      {showWishlist && (
        <div className="wishlist">
          <button className="close-btn" onClick={toggleWishlist}>
            <span className="material-symbols-outlined">close</span>
          </button>
          <h2>Wishlist</h2>
          {wishlist.length === 0 && <p>Wishlist is empty!</p>}
          <ul>
            {wishlist.map((product) => (
              <li key={product.id}>
                <div className="product-img">
                  <img src={product.image} alt={product.name} />
                  <div className="product-prices">
                    <p>{product.name}</p>
                    <h4>{product.price}</h4>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
