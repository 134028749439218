import React, { useState } from "react";

export default function Product({
  product,
  addToFavorites,
  addToCart,
  favorites,
  setFavorites,
  cart,
  setCart,
  toggleCart,
  showCart,
}) {
  const [isClicked, setIsClicked] = useState(false);

  const handleWishlistClick = () => {
    if (isClicked) {
      // If already in wishlist, remove it
      removeFromFavorites(product);
      setIsClicked(false);
    } else {
      // If not in wishlist, add it
      addToFavorites(product);
      setIsClicked(true);
    }
  };

  const removeFromFavorites = (productToRemove) => {
    const updatedFavorites = favorites.filter(
      (prod) => prod.id !== productToRemove.id
    );
    setFavorites(updatedFavorites);
  };

  const handleAddToCart = () => {
    addToCart(product);
    setCart([...cart, product]); // Add the product to the cart
  };

  const isInCart = cart.some((item) => item.id === product.id);

  return (
    <>
      <div className="item-box">
        <div className="trending-img">
          <img src={product.image} alt={product.name} />
        </div>
        <h3>{product.name}</h3>
        <div className="discount-box">{product.percent}%</div>
        <span className="discount">{product.discount}</span>
        <p>{product.price}</p>
        {!isInCart && (
          <button className="cart-btn" onClick={handleAddToCart}>
            {" "}
            <span className="material-symbols-outlined">shopping_cart</span>
          </button>
        )}

        <button
          className={`wishlist-btn ${isClicked ? "clicked" : ""}`}
          onClick={handleWishlistClick}
        >
          <span className="material-symbols-outlined">favorite</span>
        </button>
      </div>
    </>
  );
}
