import React, { useState, useEffect } from "react";

export default function ShoppingCart({
  cart,
  removeFromCart,
  toggleCart,
  showCart,
}) {
  const [itemQuantities, setItemQuantities] = useState({});

  // Update itemQuantities when cart changes
  useEffect(() => {
    const quantities = cart.reduce((quantities, product) => {
      quantities[product.id] = (quantities[product.id] || 0) + 1;
      return quantities;
    }, {});
    setItemQuantities(quantities);
  }, [cart]);

  // Calculate total price including shipping for each product
  const totalPrice = cart.reduce(
    (total, product) =>
      total +
      (parseFloat(product.price) + parseFloat(product.shippingPrice)) *
        itemQuantities[product.id],
    0
  );

  // Sum up the shipping prices for all products in the cart
  const totalShippingPrice = cart.reduce(
    (total, product) => total + parseFloat(product.shippingPrice),
    0
  );

  // Calculate total price including the summed shipping price
  const totalPriceWithShipping = totalPrice + totalShippingPrice;

  const handleCheckout = () => {
    console.log("Proceeding to checkout...");
  };

  const increaseQuantity = (productId) => {
    setItemQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: (prevQuantities[productId] || 0) + 1,
    }));
  };

  const decreaseQuantity = (productId) => {
    setItemQuantities((prevQuantities) => {
      const newQuantity = Math.max(0, (prevQuantities[productId] || 0) - 1);
      const updatedQuantities = { ...prevQuantities, [productId]: newQuantity };
      if (newQuantity === 0) {
        delete updatedQuantities[productId]; // Remove the product if quantity is zero
        removeFromCart(productId);
      }
      return updatedQuantities;
    });
  };

  return (
    <>
      <button
        className={`openShoppingCart-btn open-btn ${showCart ? "active" : ""}`}
        onClick={toggleCart}
      >
        <span className="material-symbols-outlined">shopping_cart</span>
        {cart.length > 0 && <span className="count">{cart.length}</span>}
      </button>

      {showCart && (
        <div className="shopping-cart">
          <button className="close-btn" onClick={toggleCart}>
            <span className="material-symbols-outlined">close</span>
          </button>
          <h2>Shopping Cart</h2>
          {cart.length === 0 && (
            <>
              <h3>Your shopping cart is empty.</h3>
              <p>Start shopping by adding items to your cart.</p>
            </>
          )}
          <ul>
            {cart.map((product) => (
              <li key={product.id}>
                <div className="product-img">
                  <img src={product.image} alt={product.name} />
                  <div className="product-prices">
                    <p>{product.name}</p>
                    <h4>
                      <span className="discount">{product.discount}</span>
                      {product.price}
                    </h4>
                  </div>
                  <div className="quantity-controls">
                    <button onClick={() => decreaseQuantity(product.id)}>
                      -
                    </button>
                    <span>{itemQuantities[product.id] || 0}</span>
                    <button onClick={() => increaseQuantity(product.id)}>
                      +
                    </button>
                  </div>
                  <button
                    className="remove-btn"
                    onClick={() => removeFromCart(product.id)}
                  >
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="total-price">
            <div className="shipping-price">
              Shipping Price: {totalShippingPrice}$
            </div>
            <div className="price">
              Total Price: {parseFloat(totalPriceWithShipping).toFixed(2)}$
            </div>
          </div>
          {cart.length > 0 ? (
            <button className="checkout-btn" onClick={handleCheckout}>
              Proceed to Checkout
            </button>
          ) : (
            <button className="checkout-btn" disabled>
              PROCEED TO CHECKOUT
            </button>
          )}
        </div>
      )}
    </>
  );
}
