import React, { useState } from "react";
import Product from "../../Partials/Product";
import Wishlist from "../../Partials/Wishlist";
import ShoppingCart from "../../Partials/ShoppingCart";
import LaptopImage from "../../../Images/Products/Laptop1.png";
import PhoneImage from "../../../Images/Products/Phone1.png";
import HeadphonesImage from "../../../Images/Products/Headphones1.png";
import CameraImage from "../../../Images/Products/Camera1.png";
import { Link } from "react-router-dom";

export default function Home() {
  const [wishlist, setWishlist] = useState([]);
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);

  const addToFavorites = (product) => {
    setWishlist([...wishlist, product]);
  };

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((product) => product.id !== productId);
    setCart(updatedCart);
  };

  const addToCart = (product) => {
    setCart([...cart, product]);
  };

  const toggleCart = () => {
    setShowCart(!showCart);
    setShowWishlist(false);
  };

  const toggleWishlist = () => {
    setShowWishlist(!showWishlist);
    setShowCart(false);
  };

  return (
    <>
      <section className="section margin-top">
        <h2>ALL PRODUCTS</h2>
        <div className="grid-2">
          <ProductLink name="All Products" />
          <ProductLink name="Phones" image={PhoneImage} />
          <ProductLink name="Laptops" image={LaptopImage} />
          <ProductLink name="Cameras" image={CameraImage} />
          <ProductLink name="Headphones" image={HeadphonesImage} />
        </div>
        <h2>
          TRENDING{" "}
          <span className="material-symbols-outlined">
            local_fire_department
          </span>
        </h2>
        <h3>Best products of this month!</h3>
        <div className="grid-2">
          <App
            id={1}
            name="LaptopSPlus"
            percent="-10"
            discount="1300$"
            shippingPrice="8$"
            price="1170$"
            image={LaptopImage}
            addToFavorites={addToFavorites}
            addToCart={addToCart}
            favorites={wishlist}
            setFavorites={setWishlist}
            cart={cart}
            setCart={setCart}
          />
          <App
            id={2}
            name="Camera Canon 4k"
            percent="-20"
            discount="550$"
            price="440$"
            shippingPrice="6$"
            image={CameraImage}
            addToFavorites={addToFavorites}
            addToCart={addToCart}
            favorites={wishlist}
            setFavorites={setWishlist}
            cart={cart}
            setCart={setCart}
          />
          <App
            id={3}
            name="PhonePlus"
            shippingPrice="8$"
            image={PhoneImage}
            percent="-15"
            discount="720$"
            price="612$"
            addToFavorites={addToFavorites}
            addToCart={addToCart}
            favorites={wishlist}
            setFavorites={setWishlist}
            cart={cart}
            setCart={setCart}
          />
        </div>
        <div className="wishcart-box">
          <Wishlist
            wishlist={wishlist}
            toggleWishlist={toggleWishlist}
            showWishlist={showWishlist}
          />
          <ShoppingCart
            cart={cart}
            removeFromCart={removeFromCart}
            toggleCart={toggleCart}
            showCart={showCart}
          />
        </div>
      </section>
    </>
  );
}

function App({
  id,
  name,
  price,
  percent,
  discount,
  image,
  shippingPrice,
  addToFavorites,
  addToCart,
  favorites,
  setFavorites,
  cart,
  setCart,
}) {
  const product = { id, name, price, image, percent, shippingPrice, discount };

  return (
    <>
      <Product
        key={id}
        product={product}
        addToFavorites={addToFavorites}
        addToCart={addToCart}
        favorites={favorites}
        setFavorites={setFavorites}
        cart={cart}
        setCart={setCart}
      />
    </>
  );
}

function ProductLink({ name, image }) {
  return (
    <>
      <Link to="/" className={`product-link ${!image ? "important" : ""}`}>
        {image && <img src={image} alt={name} />}
        <h3>{name}</h3>
      </Link>
    </>
  );
}
